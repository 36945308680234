import React, { useState } from "react";
import './GDashboard.css';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Paper from "@material-ui/core/Paper";
import Button from '@material-ui/core/Button';
import EnhancedTable from "./CodeTable.js";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { database } from "./Firebase";
import { useUser } from "./providers/UserProvider";
import { Redirect } from '@reach/router';
import CircularProgress from '@material-ui/core/CircularProgress';
import Async from 'react-async';
import firebase from "firebase/app";
import "firebase/auth";
import { CSVLink } from "react-csv";

const useStyles = makeStyles((theme) => ({
    logout: {
        color: "white",
    },
    button: {
        color: 'white',
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            marginTop: "5vh",
            width: "90vw",
            minHeight: "100vh",
        },
    },
    rootBar: {
        backgroundColor: "#3676c0",
    },
    paperContent: {
        margin: 50,
        textAlign: "start",
    },
    title: {
        fontWeight: 500,
    },
    logoicon: {
        height: 40,
        paddingRight: "70vw"
    },
    loading: {
        height: "75vh",
    }
}));

function GroupsDashboard() {
    const [redirect, setRedirect] = useState(false);
    const classes = useStyles();
    const { authUsers, setAuthUsers } = useUser();
    const [csvContent, setContent] = useState([]);

    function createData(code, data) {
        let appended = [];
        Object.entries(data).forEach(
            (val) => {
                appended.push(val);
            }
        );

        appended.push(code);

        return appended;
    };

    function convertData(types, index, data) {
        switch (types[index]) {
            case "str":
                return data;
            case "date":
                return data.toDate().toLocaleDateString();
            case "num":
                return data.toString();
            default:
                return "???";
        }
    }

    async function fetchCodes() {
        let codes = [];
        let header = [];
        let types = [];

        await database.collection("codigos").doc(authUsers["prefix"]).get().then((snapshot) => {
            Object.entries(snapshot.get("header")).forEach(
                (data) => {
                    data = data[1];
                    header.push({ id: data["name"], numeric: data["numeric"], disablePadding: !data["padding"], label: data["name"], sortable: data["sortable"] });
                    types.push(data["type"]);
                }
            );

            Object.entries(snapshot.data()).forEach(
                (value) => {
                    if (value[0] !== "header") {
                        codes.push(createData(value[0], Object.entries(value[1]["dados"]).map((entry) => { return convertData(types, entry[0], entry[1]); })));
                    }
                }
            );
        }).catch((e) => { });

        header.push({ id: 'code', numeric: true, disablePadding: false, label: 'Código', sortable: false });
        header.push({ id: 'copy', numeric: true, disablePadding: true, label: '', sortable: false });

        return [codes, header, types];
    }

    function handleLogout() {
        setAuthUsers();
        setRedirect(true);
        firebase.auth().signOut();
    }

    async function extractCSV() {
        var users = await database.collection("usuarios").where("codigoGrupo", ">=", authUsers["prefix"]).get();

        var csvData = [];
        var header = [];
        if (authUsers["prefix"] === "JNP") {
            header = ["Código", "Data", "Q0", "Q1", "Q2", "Q3", "Q4", "Q5", "Q6", "Q7", "Q8", "Q9", "Q10", "Q11", "Q12", "Q13", "Q14", "Q15", "Q16"]
        }

        if (authUsers["prefix"] === "MAY") {
            header = ["Código", "Data", "Q0", "Q1", "Q2", "Q3", "Q4", "Q5", "Q6", "Q7", "Q8", "Q9", "Q10"]
        }

        csvData.push(header);

        users.docs.forEach(async docUser => {
            if (docUser.exists) {
                var user = docUser.data();
                user['formularios'].filter((f) => f['classe'] === authUsers["prefix"]).forEach(
                    (form) => {
                        function digits(num) {
                            if (num < 10)
                                return '0' + num;
                            return num;
                        }

                        var timestamp = form['timestamp'].toDate();
                        var formatTimestamp = digits(timestamp.getDate()) + '/' + digits(timestamp.getMonth() + 1) + '/' + digits(timestamp.getFullYear()) + ' - ' +
                            digits(timestamp.getHours()) + ':' + digits(timestamp.getMinutes()) + ':' + digits(timestamp.getSeconds())
                        var csvForm = [user['codigoGrupo'], formatTimestamp];

                        form['respostas'].forEach((answer) => csvForm.push(answer));
                        csvData.push(csvForm);
                    }
                );
            }
        });

        setContent(csvData)
    }

    if (redirect) {
        return <Redirect noThrow to="/" />
    }

    return (
        <div className="App">
            <div>
                <AppBar position="static">
                    <Toolbar className={classes.rootBar}>
                        <Grid container direction="row" justify="space-around" alignItems="center">
                            <Grid item>
                                <img src="./logolateral.png" className={classes.logoicon} alt="" />
                            </Grid>
                            <Grid item>
                                <Button size="large" className={classes.logout} endIcon={<ExitToAppIcon />} onClick={() => { handleLogout(); }}>SAIR</Button>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </div>
            <header className="App-header2">
                <div className={classes.root} >
                    <div className={classes.paperContent}>
                        <Grid container direction="column" justify="flex-start" spacing={3}>
                            <Grid item>
                                {(authUsers["prefix"] === "JNP" || authUsers["prefix"] === "MAY")  &&
                                    <Button variant="contained" color='primary' classNames = {classes.button} onClick={() => extractCSV()}>
                                        <CSVLink
                                            style={{color: 'white', 'text-decoration': 'none'}}
                                            data={csvContent}
                                            filename="data.csv"
                                            target="_blank"> BAIXAR DADOS </CSVLink>
                                    </Button>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <Async promiseFn={fetchCodes}>
                                    {({ data, _, isLoading }) => {
                                        if (isLoading) return (
                                            <Paper className={classes.loading}>
                                                <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ minHeight: '70vh' }}>
                                                    <Grid item xs={3}>
                                                        <CircularProgress />
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        );

                                        if (data) {
                                            return (
                                                <EnhancedTable rows={data[0]} headCells={data[1]} types={data[2]} />
                                            );
                                        }
                                    }}
                                </Async>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </header>
        </div >
    );

}

export default GroupsDashboard;
