import firebase from "firebase/app";
require('firebase/firestore')

const firebaseConfig = {
    apiKey: "AIzaSyDs60UyB-lnv6UBuvJOGrnIPzUCgdFK15I",
    authDomain: "ipqapp.firebaseapp.com",
    databaseURL: "https://ipqapp.firebaseio.com",
    projectId: "ipqapp",
    storageBucket: "ipqapp.appspot.com",
    messagingSenderId: "28290302787",
    appId: "1:28290302787:web:726ab055e1bbf4bd65a5f6"
};

firebase.initializeApp(firebaseConfig);

export const database = firebase.firestore();