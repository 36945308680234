import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CheckIcon from '@material-ui/icons/Check';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { database } from './Firebase';
import { useUser } from './providers/UserProvider';
import { firestore } from 'firebase';

function findWithAttr(array, attr, value) {
    for (var i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
            return i;
        }
    }
    return -1;
}

function descendingComparator(a, b, orderBy, headCells) {
    orderBy = findWithAttr(headCells, "label", orderBy);

    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy, headCells) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy, headCells)
        : (a, b) => -descendingComparator(a, b, orderBy, headCells);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'Selecionar todos os itens.' }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        { headCell.sortable ?
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'Ordenar decrescente' : 'Ordenar crescente'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                            :
                            headCell.label
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    headCells: PropTypes.object.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
        fontWeight: 500,
    },
    selected: {
        flex: '1 1 100%',
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected, headCells, types, updateRows, selectedRow, changeRows } = props;
    const [open, setOpen] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);
    const [selectedDate, setDate] = React.useState(new Array(types.length).fill(null, 0, types.length));
    const [textData, setTextData] = React.useState(new Array(types.length));
    const [showParams, setShowParams] = React.useState(true);
    const [scode, setCode] = React.useState("");
    const [rowEdit, setRowEdit] = React.useState([]);
    const { authUsers } = useUser();

    const handleClickOpen = () => {
        setShowParams(true);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenEdit = () => {
        let auxRow = Array.from(selectedRow()[0]);

        for (const [index, type] of Object.entries(types)) {
            if (type === "date") {
                let parsedInts = auxRow[index][1].split('/');
                auxRow[index] = [auxRow[index][0], new Date(parsedInts[2], parsedInts[1] - 1, parsedInts[0])];
            }
        }

        setRowEdit(auxRow);
        setOpenEdit(true);
    }

    const handleCloseEdit = () => {
        setOpenEdit(false);
    }

    const handleDateChange = (val, index) => {
        let auxArray = Array.from(selectedDate);
        auxArray[index] = val;
        setDate(auxArray);
    }

    const handleTextChange = (val, index) => {
        let auxArray = Array.from(textData);
        auxArray[index] = val.target.value;
        setTextData(auxArray);
    }

    const handleRowEdit = (val, index, isDate) => {
        let auxRow = Array.from(rowEdit);
        auxRow[index] = isDate ? [index, val] : [index, val.target.value];
        setRowEdit(auxRow);
    }

    const typeToEntry = (entry) => {
        switch (types[entry[0]]) {
            case "date":
                return (<MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id={entry[1]["id"]}
                        label={entry[1]["label"]}
                        value={selectedDate[entry[0]]}
                        onChange={(value) => { handleDateChange(value, entry[0]) }}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>);
            default:
                return <TextField
                    margin="normal"
                    id={entry[1]["id"]}
                    label={entry[1]["label"]}
                    onChange={(value) => { handleTextChange(value, entry[0]) }}
                    fullWidth
                />;
        }
    }

    const typeToEntryWithDefault = (entry) => {
        switch (types[entry[0]]) {
            case "date":
                return (<MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id={entry[1]["id"]}
                        label={entry[1]["label"]}
                        value={rowEdit.length > 0 ? rowEdit[entry[0]][1] : ""}
                        onChange={(value) => { handleRowEdit(value, entry[0], true) }}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>);
            default:
                return <TextField
                    margin="normal"
                    id={entry[1]["id"]}
                    label={entry[1]["label"]}
                    defaultValue={rowEdit.length > 0 ? rowEdit[entry[0]][1] : "" }
                    onChange={(value) => { handleRowEdit(value, entry[0], false) }}
                    fullWidth
                />;
        }
    }

    const sendData = async () => {
        let code = "";
        let availableEntry = false;

        do {
            code = authUsers["prefix"].concat(Math.floor(Math.random() * 10000).toString().padStart(4, '0'));
            let codeSnapshot = undefined;

            await database.collection("codigos").doc(authUsers["prefix"]).get().then((snapshot) => { codeSnapshot = snapshot.get(code.toString()); });

            availableEntry = (codeSnapshot === undefined);
        } while (!availableEntry);

        let dados = [];
        let rowCode = [];

        for (var i = 0; i < types.length; i++) {
            let type = types[i];

            switch (type) {
                case "date":
                    rowCode.push(selectedDate[i].toLocaleDateString());
                    dados.push(firestore.Timestamp.fromDate(selectedDate[i]));
                    break;
                default:
                    rowCode.push(textData[i]);
                    dados.push(textData[i]);
                    break;
            }
        }

        rowCode = Object.entries(rowCode);
        rowCode.push(code);

        let data = {};
        data[code] = {
            dados: dados,
            email: textData[textData.length - 1]
        };

        await database.collection("codigos").doc(authUsers["prefix"]).update(data);
        updateRows(rowCode);

        setDate(new Array(types.length).fill(null, 0, types.length));
        setTextData(new Array(types.length));
        setCode(code);
        setShowParams(false);
    }

    const updateData = async () => {
        let code = selectedRow()[0][types.length];
        let rawData = rowEdit.slice(0, -1).map((val) => { return val[1]; });
        let rowCode = [];

        for (const [index, type] of Object.entries(types)) {
            if (type === "date") {
                rowCode.push([index, rawData[index].toLocaleDateString()]);
                rawData[index] = firestore.Timestamp.fromDate(rawData[index]);
            } else {
                rowCode.push([index, rawData[index]]);
            }
        }

        let data = {};
        data[code] = {
            dados: rawData,
            email: rawData[rawData.length - 1]
        };

        rowCode.push(code);

        await database.collection("codigos").doc(authUsers["prefix"]).update(data);

        setRowEdit([]);
        setOpenEdit(false);
        changeRows(code, rowCode);
    }
 
    return (
        <div>
            <Toolbar
                className={clsx(classes.root, {
                    [classes.highlight]: numSelected > 0,
                })}
            >
                {numSelected > 0 ? (
                    <Typography className={classes.selected} color="inherit" variant="subtitle1" component="div">
                        {numSelected} selecionados.
                    </Typography>
                ) : (
                        <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
                            <Grid container direction="row" alignItems="center">
                                <Grid item>
                                    Chaves Registradas
                            </Grid>
                                <Grid item>
                                    <IconButton aria-label="Adicionar" onClick={handleClickOpen}> <AddCircleIcon color="green" fontSize="large" /> </IconButton>
                                </Grid>
                            </Grid>
                        </Typography>
                    )}

                {numSelected == 1 ? (
                    <Tooltip title="Editar">
                        <IconButton onClick={handleOpenEdit} aria-label="edit">
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                ) : null}
            </Toolbar>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Adicionar um Código</DialogTitle>
                {
                    showParams ?
                        (
                            <div>
                                <DialogContent>
                                    <DialogContentText>
                                        Preencha os campos a seguir para associar um usuário do aplicativo a um código de grupo.
                                    </DialogContentText>
                                    <Grid container orientation="column">
                                        {
                                            Object.entries(headCells).slice(0, -2).map((headCell) => {
                                                return (<Grid item xs={12}>
                                                    {typeToEntry(headCell)}
                                                </Grid>);
                                            })
                                        }
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose} color="primary">
                                        Cancelar
                                    </Button>
                                    <Button onClick={async () => { await sendData(); }} color="primary">
                                        ENVIAR
                                    </Button>
                                </DialogActions>
                            </div>
                        )
                        :
                        (
                            <div>
                                <DialogContent>
                                    <DialogContentText>
                                        Tudo certo! O código para o usuário é:
                                    </DialogContentText>
                                    <h3>{scode}</h3>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose} color="primary">
                                        FINALIZAR
                                    </Button>
                                </DialogActions>
                            </div>
                        )
                }
            </Dialog>
            <Dialog open={openEdit} onClose={handleCloseEdit} aria-labelledby="form-dialog-title-edit">
                <DialogTitle id="form-dialog-title">Alterar uma Entrada</DialogTitle>
                <div>
                    <DialogContent>
                        <DialogContentText>
                            Faça as alterações necessárias e pressione enviar.
                        </DialogContentText>
                        <Grid container orientation="column">
                            {
                                Object.entries(headCells).slice(0, -2).map((headCell) => {
                                    return (<Grid item xs={12}>
                                        {typeToEntryWithDefault(headCell)}
                                    </Grid>);
                                })
                            }
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseEdit} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={async () => { await updateData(); }} color="primary">
                            ENVIAR
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    checkedBtn: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
        color: "#fff",
        width: 80,
        height: 35
    },
    fixBut: {
        width: 80,
        height: 35
    }
}));

export default function EnhancedTable(props) {
    const { rows, headCells, types } = props;
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState(headCells[0]["label"]);
    const [selected, setSelected] = React.useState([]);
    const [filterRows, setFilterRows] = React.useState([]);
    const [rowsUp, setRowsUp] = React.useState(rows);
    const [page, setPage] = React.useState(0);
    const [copied, setCopied] = React.useState((new Array(rows.length)).fill(false, 0, rows.length));
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const timer = React.useRef();

    React.useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    const appendRow = (row) => {
        let currentRows = Array.from(rowsUp);
        currentRows.push(row);
        setRowsUp(currentRows);
    };

    const changeRow = (rowCode, content) => {
        let currentRows = Array.from(rowsUp);
        
        for (const [index, row] of Object.entries(currentRows)) {
            if (row[types.length] == rowCode)
                currentRows[index] = content;
        }

        setRowsUp(currentRows);
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rowsUp.map((n) => n[3]);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const getFilterRows = () => {
        return filterRows;
    }

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
        setFilterRows(rowsUp.filter((row) => { return newSelected.includes(row[row.length - 1]); }));
    };

    const handleCopyClick = (index) => {
        let arrCp = (new Array(rowsUp.length)).fill(false, 0, rowsUp.length);
        arrCp[index] = true;
        setCopied(arrCp);
        timer.current = window.setTimeout(() => {
            let arrCp = (new Array(rowsUp.length)).fill(false, 0, rowsUp.length);
            setCopied(arrCp);
        }, 1500);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rowsUp.length - page * rowsPerPage);

    return (
        <div className={classes.root}>
            <Paper className={classes.paper} elevation={3}>
                <div className={classes.marginPaper}>
                    <EnhancedTableToolbar numSelected={selected.length} headCells={headCells} types={types} updateRows={appendRow} selectedRow={getFilterRows} changeRows={changeRow} />
                    <TableContainer>
                        <Table className={classes.table}>
                            <EnhancedTableHead
                                classes={classes}
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={rowsUp.length}
                                headCells={headCells}
                            />
                            <TableBody>
                                {stableSort(rowsUp, getComparator(order, orderBy, headCells))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        const isItemSelected = isSelected(row[row.length - 1]);
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row[row.length - 1]}
                                                selected={isItemSelected}
                                            >
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={isItemSelected}
                                                        onClick={(event) => handleClick(event, row[row.length - 1])}
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </TableCell>
                                                <TableCell component="th" id={labelId} scope="row" align={headCells[0]["numeric"] ? "right" : "left"} padding={headCells[0]["disablePadding"] ? "none" : "default"}>{row[0][1]}</TableCell>
                                                {
                                                    Object.entries(row).slice(1, -1).map((val) => { return <TableCell align={headCells[val[0]]["numeric"] ? "right" : "left"} padding={headCells[val[0]]["disablePadding"] ? "none" : "default"}>{val[1][1]}</TableCell> })
                                                }
                                                <TableCell align="right">{row[row.length - 1]}</TableCell>
                                                <TableCell align="center">
                                                    <CopyToClipboard text={row[row.length - 1]} onCopy={() => { handleCopyClick(index) }}>
                                                        {copied[index] ? <Button variant="contained" className={classes.checkedBtn}><CheckIcon /></Button> : <Button variant="outlined" className={classes.fixBut}>COPIAR</Button>}
                                                    </CopyToClipboard>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 20, 50]}
                        component="div"
                        count={rowsUp.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        labelRowsPerPage="Linhas por página:"
                        labelDisplayedRows={({ from, to, count, page }) => { return from + " - " + to + " de " + count; }}
                    />
                </div>
            </Paper>
        </div>
    );
}