import React, { useState } from 'react';
import './SignIn.css';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import { database } from "./Firebase";
import { Redirect } from '@reach/router';
import { useUser } from "./providers/UserProvider";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import firebase from "firebase/app";
import "firebase/auth";

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: "2%",
    },

    logo: {
        height: "30vw",
    },

    form: {
        width: '20vw',
    },

    title: {
        fontSize: 26,
        fontWeight: 500,
        marginTop: 18,
    },

    button: {
        borderRadius: "5%",
    },
}));

function SignIn() {
    const [visibilityState, setVisibilityState] = useState(false);
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [redirect, setRedirect] = useState(false);
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const { setAuthUsers } = useUser();

    function onChangeHandler(event) {
        const { name, value } = event.currentTarget;

        if (name === "login") {
            setLogin(value);
        } else if (name === "password") {
            setPassword(value);
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
    };

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    async function handleLogin() {
        var userSnapshot = null;

        await database.collection("webAuth").where("login", "==", login).get().then((snapshot) => { userSnapshot = snapshot.docs[0].data(); }).catch((e) => { setOpen(true); });

        if (userSnapshot != null && userSnapshot["pass"] === password) {
            setAuthUsers({ authState: true, usr: login, pass: userSnapshot["pass"], prefix: userSnapshot["prefix"] });

            firebase.auth().signInWithEmailAndPassword('webAuth@comvc.app', '!k@dzBhTC>G]_2XK#@jV');

            setRedirect(true);
        } else {
            setOpen(true);
        }
    }

    if (redirect) {
        return <Redirect noThrow to="/groups" />;
    }

    return (
        <div className="App">
            <header className="App-header">
            <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    Informações incorretas de Login.
                </Alert>
            </Snackbar>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item xs={4}>
                        <img src="./comvc-logo.png" alt="Logo ComVC" className={classes.logo} />
                    </Grid>
                    <Grid item xs={1} />
                    <Grid item xs={4}>
                        <Card className={classes.root}>
                            <CardContent>
                                <Grid container direction="column" justify="center" alignItems="center" spacing={4}>
                                    <Grid item xs={12}>
                                        <Typography className={classes.title} color="textPrimary" variant="h2" component="h2">
                                            Entre com seus Dados
                                                                </Typography>
                                    </Grid>
                                    <Grid item xs={12} />
                                    <Grid item xs={12}>
                                        <form noValidate autoComplete="off">
                                            <Grid container direction="column" justify="center" alignItems="flex-start" spacing={4}>
                                                <Grid item xs={12}>
                                                    <TextField name="login" label="Usuário" className={classes.form} onChange={(event) => onChangeHandler(event)} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container direction="row" justify="center" alignItems="center">
                                                        <TextField name="password" label="Senha" className={classes.form} type={visibilityState ? "" : "password"} onChange={(event) => onChangeHandler(event)} />
                                                        <IconButton aria-label="Mostrar/Esconder Senha" onClick={() => { setVisibilityState(!visibilityState) }}> {visibilityState ? <VisibilityOffIcon /> : <VisibilityIcon />} </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Grid>
                                    <Grid item xs={12} />
                                    <Grid item xs={12}>
                                        <Button variant="contained" color="primary" size="large" className={classes.button} onClick={async () => { await handleLogin(); }} >ENTRAR</Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </header>
        </div>
    );
}

export default SignIn;