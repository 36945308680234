import React, { useState } from "react";
import Application from "./Application";
import UserContext from "./providers/UserProvider";

function App() {
    var signedUsers = null;
    
    try {
        signedUsers = JSON.parse(localStorage.getItem("data"));
    } catch (e) { console.log(e); }

    const [ authUsers, setAuthUsers ] = useState(signedUsers);

    const setTokens = (data) => {
        localStorage.setItem("data", JSON.stringify(data));
        setAuthUsers(data);
    };  

    return (
        <UserContext.Provider value = { { authUsers, setAuthUsers: setTokens } }>
            <Application />
        </UserContext.Provider>
    );
}

export default App;