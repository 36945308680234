import React from "react";
import { Router } from "@reach/router";
import SignIn from "./SignIn";
import GroupsDashboard from "./GroupsDashboard";
import { useUser } from "./providers/UserProvider";

function Application() {
  const {authUsers} = useUser();
  
  return (
      <Router>
        {(authUsers != null && authUsers["authState"]) ? <GroupsDashboard path = "/" /> : <SignIn path="/" />}
      </Router>
  );
}
export default Application;